@media screen {
  .page {
    min-height: 297mm;
    width: 210mm;
  }
}

@media print {
  .pages,
  .page {
    min-height: calc(297mm - (var(--pagedjs-margin-top) + var(--pagedjs-margin-bottom)));
    max-height: calc(297mm - (var(--pagedjs-margin-top) + var(--pagedjs-margin-bottom)));
    min-width: calc(210mm - (var(--pagedjs-margin-left) + var(--pagedjs-margin-right)));
    max-width: calc(210mm - (var(--pagedjs-margin-left) + var(--pagedjs-margin-right)));
  }
}

@page {
  size: A4 portrait;
}
