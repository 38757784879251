import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Previewer, registerHandlers } from 'pagedjs';
import { firstValueFrom, timer } from 'rxjs';
import { Handlers } from './handlers';
import { PagedLoaderComponent } from './paged-loader.component';
import { PagedPageComponent } from './paged-page.component';
import { PagedService } from './paged.service';
import { setBackgroundImagesAbsolutePath, setImagesAbsolutePath, setPrintStylesheetAbsolutePath } from './path-converter';

@Directive({
  selector: '[lsuReportPaged]',
  standalone: true,
})
export class PagedDirective implements OnInit, OnDestroy, AfterViewInit {
  pagesReady = false;
  initialized = true;

  // the zoom will be set to every page content
  @Input() set zoom(zoomValue: number | string) {
    zoomValue = zoomValue + '';
    this.document.documentElement.style.setProperty('--zoom', zoomValue);
  }

  // zoom the complete print page.
  // sometimes the complete viewport should be zoomed. This will only reflect to the print, not the webview
  @Input() set viewportZoom(zoomValue: number | string) {
    zoomValue = zoomValue + '';
    this.document.documentElement.style.setProperty('--viewportZoom', zoomValue);
  }

  @Input() set margin(margin: string | null) {
    if (!margin) {
      return;
    }
    const marginTopBottom = margin.split(' ')[0];
    const marginLeftRight = margin.split(' ')[1];
    this.document.documentElement.style.setProperty('--margin', margin);
    this.document.documentElement.style.setProperty('--margin-left', marginLeftRight);
    this.document.documentElement.style.setProperty('--margin-right', marginLeftRight);
    this.document.documentElement.style.setProperty('--margin-top', marginTopBottom);
    this.document.documentElement.style.setProperty('--margin-bottom', marginTopBottom);
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(''));
    style.innerText = `@page {margin: ${margin} !important}`;
    document.head.appendChild(style);
  }

  /** @deprecated
   * use showPreview instead */
  @Input() pagedjs = true;
  @Input() set showPreview(showPreview: boolean) {
    this.pagedjs = !showPreview;
  }

  @Input() set showPageNumbers(showPageNumbers: string | boolean) {
    if (showPageNumbers) {
      const prefix = typeof showPageNumbers === 'string' ? showPageNumbers : '';
      this.setPageNumbers(prefix);
    }
  }

  // Use initialize when adding async properties to templates
  @Input() set initialize(initialized: boolean) {
    this.initialized = initialized;
    if (initialized && this.pagesReady) {
      this.createPreview();
    }
  }

  @ContentChild(PagedLoaderComponent) pageLoader?: PagedLoaderComponent;

  @ContentChildren(PagedPageComponent) set pages(p: PagedPageComponent) {
    this.pagesReady = true;
    if (this.initialized) {
      this.createPreview();
    }
  }

  @HostBinding('class') get classes() {
    return {
      'paged-report': true,
    };
  }

  @Output() afterRendered: EventEmitter<void> = new EventEmitter<void>();
  @Output() beforeParsed: EventEmitter<typeof Previewer> = new EventEmitter<typeof Previewer>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private pagedService: PagedService,
  ) {}

  ngOnInit() {
    registerHandlers(Handlers);
    this.pagedService.paged = new Previewer();
    this.beforeParsed.emit(this.pagedService.paged);
    this.document.querySelector('html')?.classList.add('print');
    this.document.body.classList.add('print');
  }

  async ngAfterViewInit() {
    if (!this.pagedjs && this.initialized) {
      await this.loadingReady();
    }
  }

  ngOnDestroy() {
    this.document.querySelector('html')?.classList.remove('print');
    this.document.body.classList.remove('print');
  }

  removeStyles(doc = document) {
    const inlineStyles = Array.from(doc.querySelectorAll('style:not([data-pagedjs-inserted-styles])'));
    const elements = inlineStyles;
    return (
      elements
        // preserve order
        .sort(function (element1, element2) {
          const position = element1.compareDocumentPosition(element2);
          if (position === Node.DOCUMENT_POSITION_PRECEDING) {
            return 1;
          } else if (position === Node.DOCUMENT_POSITION_FOLLOWING) {
            return -1;
          }
          return 0;
        })
        // extract the href
        .map((element) => {
          if (element.nodeName.toLowerCase() === 'style') {
            const obj = {};
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj[window.location.href] = element.textContent;
            element.remove();
            return obj;
          }
          if (element.nodeName.toLowerCase() === 'link') {
            element.remove();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return element.href;
          }
          // ignore
          console.warn(`Unable to process: ${element}, ignoring.`);
        })
    );
  }

  async createPreview() {
    if (this.pagedjs) {
      await this.createPaged();
    }

    await this.loadingReady();
    this.convertRelativeToAbsolutePaths();
  }

  private convertRelativeToAbsolutePaths() {
    setPrintStylesheetAbsolutePath(this.document);
    setImagesAbsolutePath(this.document);
    setBackgroundImagesAbsolutePath(this.document);
  }

  private async createPaged() {
    this.pagedService.paged.afterRendered = () => {
      this.afterRendered.emit();
    };

    let styles = this.removeStyles(document);
    styles = styles.filter((style: unknown) => typeof style !== 'string');
    this.replaceLineBreaks();
    const fragment = this.getFragment();

    await this.pagedService.paged.preview(fragment, styles, document.body);
    this.setCustomRootClassNames();
  }

  private getFragment(): DocumentFragment {
    const content = document.body.querySelectorAll('.preview > *');
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < content.length; i++) {
      fragment.appendChild(content[i]);
    }

    return fragment;
  }

  /* replace <br> tags with a paragraph tag */
  /* this is needed because br tags are hard to calculate for page breaks in pagedjs */
  /* insert non-breaking space to get the same line height */
  private replaceLineBreaks() {
    document.body.querySelectorAll('br').forEach((x) => {
      const p = document.createElement('p');
      p.innerHTML = '&nbsp;';
      x.replaceWith(p);
    });
  }

  private async loadingReady() {
    if (this.pageLoader) {
      // timer for a smoother transition
      const source = timer(700);
      await firstValueFrom(source);
      this.pageLoader.ready = true;
    }

    this.document.documentElement.id = 'signal-puppeteer-document-ready';
  }

  private setCustomRootClassNames() {
    const classes = this.elementRef.nativeElement.getAttribute('class');
    const root = document.querySelector('.pagedjs_pages');
    if (root && classes) {
      classes.split(' ').forEach((c: string) => root.classList.add(c));
    }
  }

  private setPageNumbers(prefixPageNumbers: string) {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(''));
    style.innerText = `@page {@bottom-right { content: "${prefixPageNumbers} "  counter(page) } }`;
    document.head.appendChild(style);
  }
}
