:host {
  display: block;
}

.page {
  transform: scale(var(--zoom, 1));
  background-color: #fff;
}

html.print,
body.print {
  margin: 0;
  padding: 0;
  background-color: #fff;

  @media screen {
    background-color: #eee !important;
    .paged-report .page {
      margin: 1cm auto;
      flex: none;
      box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
      box-sizing: border-box;
      padding: var(--margin, 2cm 2cm);
    }

    .paged-report .pages {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      transform-origin: 0 0;
      margin: 0 auto;
    }
    .paged-report .page {
      box-sizing: border-box;
    }

    .lsu-report-paged-actions .actions-right {
      right: var(--spacer3);
      bottom: var(--spacer3);
    }

    .lsu-report-paged-actions .actions-left {
      left: var(--spacer3);
      top: var(--spacer3);
    }

    .lsu-report-paged-actions .actions {
      display: block !important;
    }
  }
}

@media print {
  html,
  body {
    zoom: var(--viewportZoom, 1);
    background-color: #fff;
    height: 99%;
    width: 100%;

    .pagedjs_page {
      background-color: #fff;
    }

    .pagedjs_margin-top {
      grid-template-columns: 0 100% 0;
    }

    .pagedjs_margin.hasContent {
      .pagedjs_margin-content {
        height: 100%;
        text-align: left;
      }
    }

    .lsu-report-paged-actions .actions,
    lsu-report-paged-loader {
      display: none;
    }
  }
}

@page {
  margin: var(--pagedjs-margin-top, 2cm) var(--pagedjs-margin-right, 2cm) var(--pagedjs-margin-bottom, 2cm) var(--pagedjs-margin-left, 2cm);
}

.page {
  break-before: page;
  display: block;
}

.page-break {
  break-before: page;
}

.page-break-inside-avoid {
  page-break-inside: avoid !important;
}

.page-break-inside-auto {
  break-inside: auto !important;
}

table,
.mat-mdc-table,
.lib-table {
  page-break-inside: auto;
  page-break-after: auto;
}

tr,
.mat-mdc-header-row,
.mat-mdc-row,
td,
.mat-mdc-cell,
.mat-mdc-header-cell {
  page-break-inside: avoid;
  page-break-after: auto;
}

// we have to unset any min height set on the table.
// Because pagedjs breaks tables when they don't fit on a page
// it sometimes happens a table with one row will start on a new page.
// A min height makes the row the same height as the min height
.mat-mdc-table {
  min-height: unset !important;
}

.mat-mdc-header-row {
  display: table-header-group !important;
}

thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}

.mat-mdc-table {
  display: table !important;
  border-collapse: collapse;
}

.mat-mdc-row,
.mat-mdc-header-row {
  display: table-row;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  min-height: unset;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
  display: table-cell;
  padding: var(--spacer2) 0;

  &:not(:first-child) {
    text-align: right;
  }

  &:first-child {
    padding-left: var(--spacer2);
  }

  .header-cell {
    padding-bottom: 0;
  }
}

table {
  width: 100%;
}
