import { DOCUMENT, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'lsu-report-paged-loader',
  templateUrl: './paged-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatProgressSpinnerModule],
})
export class PagedLoaderComponent implements AfterViewInit {
  isReady = false;

  set ready(isReady: boolean) {
    this.document.documentElement.classList.remove('overflow-hidden');
    this.isReady = isReady;
    this.changeDetectorRef.markForCheck();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.document.documentElement.classList.add('overflow-hidden');
  }
}
