import { DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NestJsControllerClient } from '@shared/data-access/common';
import { JDocument, saveFile } from '@shared/util/code';
import { LoadingIndicatorService } from '@shared/util/infrastructure';
import { SlugifyPipe } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { DownloadService } from '../download/download.service';

@Component({
  selector: 'lsu-report-paged-actions',
  templateUrl: './paged-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [SlugifyPipe],
  imports: [NgIf, MatButtonModule, MatIconModule],
})
export class PagedActionsComponent {
  @HostBinding('class') class = 'lsu-report-paged-actions';
  @Input() color: ThemePalette = 'accent';
  @Input() showDownloadAsDocx = true;
  @Input() fileName?: string;
  @Input() returnUrl?: string | number;
  @Input() generatePdfFromHTML = false; // set to true when the page is behind a login
  @Input() set downloadPdfFromLocationUrl(url: string) {
    this.showDownloadAsDocx = false;
    this._downloadPdfFromLocationUrl = url;
  }

  private _downloadPdfFromLocationUrl?: string;

  get showLeftActions() {
    return this.returnUrl;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private nestJsControllerClient: NestJsControllerClient,
    private slugifyPipe: SlugifyPipe,
    private loadingIndicatorService: LoadingIndicatorService,
    private downloadService: DownloadService,
  ) {}

  @HostListener('window:keydown', ['$event'])
  onKeyPress(evt: KeyboardEvent) {
    if ((evt.ctrlKey || evt.metaKey) && evt.code.toLowerCase() === 'keyp') {
      window.scrollTo(0, 0);
      if (this._downloadPdfFromLocationUrl) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        void this.generateIframePage(this._downloadPdfFromLocationUrl!);
      }
    }
  }

  onPrint() {
    window.scrollTo(0, 0);
    this._downloadPdfFromLocationUrl ? this.generateIframePage(this._downloadPdfFromLocationUrl) : window.print();
  }

  onGoBack() {
    if (this.returnUrl === -1) {
      history.go(-1);
    } else {
      window.location.href = <string>this.returnUrl || '';
    }
  }

  async onDownloadPdfClick() {
    if (this.generatePdfFromHTML) {
      await this.download(0, this.getCleanHtml(this.document.documentElement), '.pdf');
    } else {
      await this.downloadFromLocation();
    }
  }

  async onDownloadDocx() {
    await this.download(1, this.getCleanHtml(this.document.documentElement), '.docx');
  }

  private async download(documentType: number, html: string, ext: string) {
    this.loadingIndicatorService.showSpinner();

    const jDocument: JDocument = new JDocument(html, documentType);

    const fileResponse = await firstValueFrom(this.nestJsControllerClient.generateDocumentFromHtmlDocument(jDocument));

    if (this.fileName) {
      fileResponse.fileName = this.slugifyPipe.transform(this.fileName) + ext;
    }

    saveFile(fileResponse);
  }

  private async downloadFromLocation() {
    const url = this._downloadPdfFromLocationUrl ? this._downloadPdfFromLocationUrl : document.location.href.split('#')[0];
    const fileName = this.fileName ? this.slugifyPipe.transform(this.fileName) + '.pdf' : undefined;
    await this.downloadService.create(url, {
      fileName,
      shouldWaitForReadySelector: true,
    });
  }

  private async getPDFFileResponse(url: string) {
    const fileName = this.fileName ? this.slugifyPipe.transform(this.fileName) + '.pdf' : undefined;
    return await this.downloadService.getFileResponse(
      url,
      this.downloadService.getRequestBody({
        fileName,
        shouldWaitForReadySelector: true,
      }),
    );
  }

  private convertLinkStylesheet(element: HTMLElement) {
    element.querySelectorAll('link[href][rel="stylesheet"]').forEach((link) => {
      const htmlLinkElement = link as HTMLLinkElement;
      htmlLinkElement.setAttribute('href', htmlLinkElement.href);
    });
  }

  private convertAnchorLinks(element: HTMLElement) {
    element.querySelectorAll('a[fragment]').forEach((anchor) => {
      anchor.setAttribute('data-originalUrl', anchor.getAttribute('href')!);
      anchor.setAttribute('href', '#' + anchor.getAttribute('fragment'));
    });
  }

  private revertAnchorLinks(element: HTMLElement) {
    element.querySelectorAll('a[data-originalUrl]').forEach((anchor) => {
      anchor.setAttribute('href', anchor.getAttribute('data-originalUrl')!);
    });
  }

  private async generateIframePage(url: string) {
    this.loadingIndicatorService.manualShowSpinner();

    const fileResponse = await this.getPDFFileResponse(url);

    const hideFrame = document.createElement('iframe');
    hideFrame.onload = () => {
      this.loadingIndicatorService.manualHideSpinner();
      hideFrame.contentWindow!.onbeforeunload = () => document.body.removeChild(hideFrame);
      hideFrame.contentWindow!.onafterprint = () => document.body.removeChild(hideFrame);
      hideFrame.contentWindow!.focus();
      hideFrame.contentWindow!.print();
    };
    hideFrame.style.position = 'fixed';
    hideFrame.style.right = '0';
    hideFrame.style.bottom = '0';
    hideFrame.style.width = '0';
    hideFrame.style.height = '0';
    hideFrame.style.border = '0';
    hideFrame.src = URL.createObjectURL(fileResponse.data);
    document.body.appendChild(hideFrame);
  }

  private getCleanHtml(htmlElement: HTMLElement) {
    this.convertLinkStylesheet(htmlElement);
    this.convertAnchorLinks(htmlElement);
    const html = htmlElement.outerHTML;
    this.revertAnchorLinks(htmlElement);
    return html;
  }
}
